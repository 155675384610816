<template>
  <div v-if="question">
    <sqr-input-textarea
      :label="question.name"
      :placeholder="question.placeholder"
      :required="question.required"
      :value="answer"
      @change="$emit('change', $event)"
      :disabled="disabled"
      :v="v"
    />
  </div>
</template>

<script>
import SqrInputTextarea from '@/sqrd-ui/SqrInputTextarea';
export default {
  name: 'TextareaView',
  components: { SqrInputTextarea },
  props: {
    question: { type: Object, required: true },
    answer: { type: String },
    disabled: { type: Boolean },
    v: { type: Object }
  }
};
</script>
